import styled from 'styled-components';

import { media } from '../../../styles/media';
import { HRSectionProps } from './HRSection.types';

export const Container = styled.hr<HRSectionProps>`
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    hsl(300, 15%, 70%, 1),
    rgba(0, 0, 0, 0)
  );

  margin: 16px 0 16px 0;

  ${media.from_tablet`
    margin: 32px 0 32px 0;
  `}
`;
